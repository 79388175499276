/*
    Datetime utilities functions
*/

frappe.provide("bond_assets.datetime");

$.extend(bond_assets.datetime, {
	user_month_year_format: "MMMM YYYY",
    user_date_format: "MMMM DD, YYYY",
    user_time_format: "hh:mm A",
    user_datetime_format: "MMMM DD, YYYY hh:mm A",
	picker_date_format: "MM dd, yyyy",
	picker_time_format: "hh:ii AA",
	picker_datetime_format: "MM dd, yyyy hh:ii AA",
});

$.extend(frappe.datetime, {

	validate: function(d) {
		return moment(d, [
			frappe.defaultDateFormat,
			frappe.defaultTimeFormat,
			frappe.defaultDatetimeFormat
		], true).isValid();
	},
	get_user_time_fmt: function() {
		return bond_assets.datetime.user_time_format;
	},

	get_user_date_fmt: function() {
		return bond_assets.datetime.user_date_format;
	},

	get_user_fmt: function() {  // For backwards compatibility only
		return bond_assets.datetime.user_date_format;
	},

	str_to_user: function(val, only_time = false) {
		if(!val) return "";

		var user_time_fmt = frappe.datetime.get_user_time_fmt();
		if(only_time) {
			return moment(val, frappe.defaultTimeFormat)
				.format(user_time_fmt);
		}
		var user_date_fmt = frappe.datetime.get_user_date_fmt();

		let format;
		if(typeof val !== "string" || val.indexOf(" ")===-1) {
			moment_obj = moment(val);
			format = user_date_fmt;
		} else {
			format = user_date_fmt + " " + user_time_fmt;
			moment_obj = moment(val, "YYYY-MM-DD HH:mm:ss");
		}
		if(frappe.get_route() && !in_list(frappe.get_route(), "query-report")){

			if(frappe.boot.time_zone && frappe.boot.time_zone.system && frappe.boot.time_zone.user){
				moment_obj = moment_obj
					.tz(frappe.boot.time_zone.system)
					.clone()
					.tz(frappe.boot.time_zone.user);
			}
		}
		return moment_obj.format(format);
	},

	convert_to_user_tz: function (date, format) {
		// format defaults to true
		// Converts the datetime string to system time zone first since the database only stores datetime in
		// system time zone and then convert the string to user time zone(from User doctype).
		let date_obj = null;

		if (frappe.boot.time_zone && frappe.boot.time_zone.system && frappe.boot.time_zone.user && frappe.get_route() && !in_list(frappe.get_route(), "query-report")) {
			date_obj = moment
				.tz(date, frappe.boot.time_zone.system)
				.clone()
				.tz(frappe.boot.time_zone.user);
		} else {
			date_obj = moment(date);
		}

		return format === false ? date_obj : date_obj.format(frappe.defaultDatetimeFormat);
	},

	user_to_str: function(val, only_time = false) {

		var user_time_fmt = frappe.datetime.get_user_time_fmt();
		if(only_time) {
			return moment(val, user_time_fmt)
				.format(frappe.defaultTimeFormat);
		}

		var user_fmt = frappe.datetime.get_user_date_fmt();
		var system_fmt = "YYYY-MM-DD";

		if(val.split(" ").length === 5) {
			user_fmt += " " + user_time_fmt;
			system_fmt += " HH:mm:ss";
		}
		
		// user_fmt.replace("YYYY", "YY")? user might only input 2 digits of the year, which should also be parsed
		return moment(val, [user_fmt.replace("YYYY", "YY"),
			user_fmt]).locale("en").format(system_fmt);
	},
	month_year_user_to_str(val){
		return moment(val, "MM-YYYY").format("MMMM YYYY");
	},

	month_year_str_to_user(val){
		return moment(val, "MM-YYYY").format("MMMM YYYY");
	}
});
